/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 */

/* 
 html {
  --cursor-color: #333
}

html.dark-mode {
  --cursor-color: #fff
} */

#cursor {
  color: black;
  /* position: fixed;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--cursor-color);
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  transition: all 0.1s ease-out;
  transition-property: width, height, border;
  will-change: width, height, transform, border; */
}

body,
html {
  font-family: proxima-nova, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  /* cursor: default; */
  margin: 0;
  background-color: #f6f4eb;
  height: 100%;
  width: 100%;
  /* scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none; */
}

#root {
  /* margin: 45px; */
  height: 100%;
  padding-top: 0.1px;
  /* overflow: auto; */
  /* padding-top: 1px; */
  /* scroll-snap-type: x mandatory; */
}
/* 
a {
  text-decoration: none;
  color: #91C8E4;
} */

::-moz-selection {
	background-color: rgba(177, 236, 251, .5);
}

::selection {
	background-color: rgba(177, 236, 251, .5);
}

a {
  color: #629fc6;
  text-decoration: none;
}

li {
  list-style: none;
}

.navbar a,
.page a {
  position: relative;
  color: #629fc6;
  text-decoration: none;
  transition: color 0.25s ease-in-out;
}

.navbar a::after,
.page a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 0;
  background: currentColor;
  transition: width 0.3s ease-in-out;
}

.navbar a:hover,
.page a:hover {
  color: #91c8e4;
}

.navbar a:hover::after,
.page a:hover::after {
  width: 100%;
}

.namenav {
  position: fixed;
  top: 45px;
  left: 45px;
  margin: 0;
  padding: 0;
  z-index: 10;
}

.namenav a {
  color: #104160;
  font-family: "Amandine", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 23pt;
  font-weight: 500;
  text-transform: lowercase;
}

/* .namenav a {
  color: #3C80AC;
  font-family: "Bodoni Moda", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: lowercase;
} */

.sidenav {
  transform: rotate(90deg);
  /* margin: 10px; */
  position: fixed;
  top: 52px;
  right: -70px;

  display: flex;
  /* flex-direction: row; */
  /* justify-content: space-between; */
  /* padding: 0.2rem calc((100vw - 1000px) / 2); */
  z-index: 10;
  /* padding-inline-start: 40px; */
}

.sidenav li {
  padding-inline-start: 50px;
}

.sidenav a {
  color: #104160;
  font-family: mr-eaves-modern, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 17pt;
  font-weight: 400;
  text-transform: lowercase;
}

.footer {
  text-align: center;
  padding-bottom: 10px;
  font-size: 10pt;
}

.index-container {
  margin: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.top,
.bottom {
  /* flex: 1;
  display: flex;
  align-items: center;
  justify-content: center; */
  height: calc(100vh / 5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  border: none;
  background: transparent;
}

.top {
  top: 0px;
}

.bottom {
  bottom: 0px;
}

.top:hover, .bottom:hover {
  cursor: none;
}

/* .project-wrapper {
  flex: 1;
  overflow: auto;
} */

.project-wrapper {
  margin: 0 auto;
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  /* gap: 1rem; */
  /* height: calc(180px + 1rem); */
  padding: 0;
  /* max-width: 480px; */
  width: 100vw;
  height: 100vh;

  scroll-snap-type: y mandatory;
  overflow-x: auto;
  overscroll-behavior-y: contain;
}

.proj-button {
  /* border: none;
  background: transparent; */
  width: 60vw;
  height: 100vh;

  /* position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.proj-container {
  scroll-snap-align: start;
  height: 100%;
  width: 100%;
  /* height: 100vh; */
  /* max-height: none; */
  /* width: 100vw; */
  /* max-width: none; */
  object-fit: contain;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project {
  margin: auto;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 2fr 2fr 2fr 1fr 1fr 2fr;
  grid-template-rows: 3fr 3fr 4fr 3fr;

  /* width: 60%;
  height: 60vh; */
  width: 100%;
  height: 60%;
  justify-content: center;
  align-items: center;

  /* border-top: 1px solid;
  border-image: linear-gradient(to left, #000 30%, transparent 0%) 100% 1;
  border-right: 1px solid;
  border-image: linear-gradient(to bottom, #000 30%, transparent 0%) 1; */

  /* border: 1.5px solid;
  border-image: linear-gradient(to bottom left, #ffd37b 15%, transparent 0%) 1; */

  /* margin-top: 20vh;
  margin-bottom: 0; */

  position: relative;
  z-index: 1;
}

/* .project h1 {
  font-size: 2rem;
  font-weight: 600;
  color: #42c2dc;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
} */

/* .spinner {
  width: 140px;
  height: 140px;
  background-color: cornflowerblue;
  margin: auto;
  margin-top: 55%;
} */

.proj-title {
  font-family: "Amandine", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 80px;
  font-weight: 600;
  color: #629fc6;
  margin: 0;
  margin-left: 12%;
  padding: 0;
  text-transform: lowercase;

  grid-column: 7 / 10;
  grid-row: 3 / 4;
  align-self: flex-end;
  /* border-top: 1px solid #000; */
  z-index: 3;
}

.proj-title:after {
  content: ".";
}

.proj-subtitle {
  font-family: mr-eaves-modern, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 16pt;
  font-weight: 400;
  text-transform: lowercase;
  color: #000;
  /* font-size: 1.5rem;
  font-weight: 600;
  color: #42c2dc;
  margin: 0;
  padding: 0;
  text-transform: uppercase; */
  margin: 0;
  /* border-bottom: #A7ECEE 1.5px solid; */

  grid-column: 1 / 4;
  grid-row: 1 / 2;
  align-self: end;
  justify-self: end;

  z-index: 3;
}

.proj-line {
  border-bottom: #cd798d 1.5px solid;
  padding-top: 10px;

  grid-column: 1 / 4;
  grid-row: 2 / 3;
  align-self: start;
  z-index: 3;
}

.proj-line-side {
  border-left: #cd798d 1.5px solid;
  padding-top: 10px;

  grid-column: 1 / 2;
  grid-row: 3 / 4;
  justify-self: start;
  z-index: 3;
}

.proj-index {
  font-family: mr-eaves-modern, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 16pt;
  text-transform: lowercase;
  color: #cd798d;

  grid-column: 10 / 11;
  grid-row: 4 / 5;
  justify-self: end;
  z-index: 3;
}

.proj-img {
  /* width: 400px;
  height: 400px; */
  width: 90%;
  max-height: 75vh;
  /* width: auto;
  height: auto; */
  grid-column: 2 / 8;
  grid-row: 1 / 4;
  align-self: center;
  justify-self: center;
  z-index: 2;
  object-fit: contain;
  object-position: center;
}

.proj-img-sub {
  /* width: 400px;
  height: 400px; */
  /* width: 100%; */
  grid-column: 3 / 9;
  grid-row: 2 / 5;
  /* align-self: center; */
  z-index: 0;
  /* object-fit: cover;
  object-position: center; */
}
/* .proj-view {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  grid-column: 3 / 4;
  grid-row: 3 / 4;
} */

/* .proj-line {
  grid-column: 1 / 4;
  grid-row: 3 / 4;
  align-self: flex-end;
  border-top: 1px solid #000;
  width: 100%;
} */
/* .parallax-line {
  transition: width 2s ease-in-out;
} */

/* body {
  scroll-snap-type: x mandatory;
}

section {
  height: 100vh;
  width: 100vw;
  
} */
.button-container {
  position: fixed;
  top: 0;
  left: 0;

  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */

  /* width: calc(100vw - 100px);
  height: calc(100vh - 100px); */
  display: grid;
  /* grid-template-columns: 50px 1fr 1fr 1fr 50px;
  grid-template-rows: 50px 1fr 50px; */
  grid-template-rows: 50px 1fr 1fr 1fr 50px;
  grid-template-columns: 50px 1fr 50px;

  z-index: 0;
  /* margin: 50px; */
  /* margin-left: auto;
  margin-right: auto; */
  width: 100vw;
  height: 100vh;
}

.button-half-page {
  border: none;
  background: transparent;
}

.button-half-page:first-child {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

.button-half-page:last-child {
  grid-column: 2 / 3;
  grid-row: 4 / 5;
}

.button-half-page:hover {
  cursor: none;
}

/* .cursor {
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  cursor: none;
} */

.arrow-cursor {
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  /* width: 100px;
  height: 100px; */
  font-family: mr-eaves-modern, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 40px;
  color: #ffeebb;
  /* content: "view"; */
  /* background: url("https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvdHAyNDctZWxlbWVudC0wMDk4LWt6b3hsaGgwLnBuZw.png") no-repeat; */
  background-size: contain;
  background-position: center;
  cursor: none;
  transform: translate(-50%, -50%);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.project .proj-title,
.project .proj-subtitle,
.project .proj-img {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.project .proj-title {
  animation-delay: 0.25s;
}

.project .proj-subtitle {
  animation-delay: 0.5s;
}

.project .proj-img {
  animation-delay: 0.75s;
}

.project .proj-img-sub {
  animation-delay: 1s;
}

/*Project Page*/
.page p {
  font-size: 11.5pt;
  margin: 0;
}

.page-title-container {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 4fr 1fr 4fr;
  column-gap: 1.5em;
  row-gap: 0.5em;
  width: 60vw;
  height: 65vh;
  overflow: auto;
  margin: 0 auto;
  margin-top: 20vh;
  margin-bottom: 5vh;
  z-index: 1;
}

.page-title {
  font-family: "Amandine", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 80px;
  font-weight: 600;
  color: #3c80ac;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  text-transform: lowercase;

  grid-column: 1 / 2;
  grid-row: 1 / 2;
  align-self: start;
  justify-self: start;
}

.page-title::after {
  content: ".";
}

.page-subtitle {
  font-family: mr-eaves-modern, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-weight: 400;
  text-transform: lowercase;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  align-self: end;
  justify-self: start;
  margin: 0;
}

.page-category {
  font-family: mr-eaves-modern, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 16pt;
  font-weight: 400;
  text-transform: lowercase;
  /* color: #000; */
  /* font-size: 1.5rem;
  font-weight: 600;
  color: #42c2dc;
  margin: 0;
  padding: 0;
  text-transform: uppercase; */
  grid-column: 1 / 3;
  grid-row: 4 / 5;
  align-self: start;
  justify-self: end;
  text-align: right;
  margin: 0;
}

.page-desc {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  align-self: end;
  justify-self: start;
  margin: 0;
}

.page-desc-link,
.page-desc-password {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  align-self: end;
  justify-self: start;
  margin: 0;
  color: #000;
  font-family: proxima-nova, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.page-desc-password input {
  /* width: 100%; */
  height: 30px;
  font-size: 20px;
  background-color: #cfdee3;
  border: 0;
  border-radius: 4px;
  padding: 0 10px;
  margin: 0 10px 0 2px;
}

.page-desc-password input:hover {
  cursor: none;
}

.page-desc-password button {
  font-size: 14px;
  /* border-radius: 5px; */
  background-color: transparent;
  border: 0;
  transition: all 0.3s ease;
}

.page-desc-password button:hover {
  cursor: none;
  color: #91c8e4;
}

.page-validate {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
  align-self: start;
  justify-self: start;
  margin: 0;
  color: #cd798d;
}

.page-img {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 100px;
  margin: 0;
  /* height: 100vh;
  width: auto; */
  object-fit: cover;
  object-position: center;
}

.content-container {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 6fr;
  column-gap: 1.5em;
  row-gap: 4em;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  /* margin-left: 17.5vw; */
  width: 75vw;
}

.content-header {
  grid-column: 1 / 2;
  align-self: end;
  justify-self: start;
  margin: 0;
  font-family: "Amandine", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #3c80ac;
  text-transform: lowercase;
}

.content-header::after {
  content: ".";
}

.content-body {
  grid-column: 2 / 3;
  align-self: end;
  justify-self: start;
  margin: 0;
}

.content-body ul,
.content-body ol {
  margin: 0;
  padding-left: 1em;
}

.content-body ul > li {
  padding-left: 0.5em;
  list-style: circle;
}

.content-body ol > li {
  padding-left: 0.5em;
  list-style: lower-roman;
}

.list-grid {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); */
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 10px;
  padding: 0;
}

/* .content-body figure, .content-body figcaption {
  margin: 0;
  padding: 0;
  display: inline-block;
} */

.content-img {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
  border-radius: 5px;
}

.content-img-half {
  width: calc(50% - 10px);
  /* margin-right: 10px; */
}

.content-img-half:nth-child(even) {
  margin-right: 10px;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}
.content-vid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* .content-whole {
  align-self: center;
  justify-self: center;
  margin: 0;
} */

.content-whole img {
  width: calc(100% - 100px);
  height: auto;
  object-fit: contain;
  object-position: center;
  border-radius: 10px;
  margin: 0 50px;
  position: relative;
  z-index: 1;
}

.about .page-title,
.about .page-subtitle,
.about .page-category,
.about .page-desc,
.about .page-desc-link {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
}

.about .page-title,
.about .page-desc,
.about .page-desc-link {
  padding: 0 10px;
  margin: 0;
}

.about .page-title {
  font-size: 65px;
  line-height: 75px;
}

@media screen and (max-width: 820px) {
  .sidenav {
    right: -90px;
  }
  .proj-button {
    width: 70vw;
  }
  .proj-title {
    font-size: 70px;
  }
  .proj-subtitle {
    grid-column: 1 / 5;
  }
  .proj-img {
    width: 100%;
  }
  .page-title-container {
    width: 80vw;
    height: 75vh;
  }

  .about .page-title {
    font-size: 55px;
    line-height: 65px;
  }
  /* .page-title {
    font-size: 70px;
  }
  .page-subtitle, .page-category {
    font-size: 15pt;
  } */
}

@media screen and (max-width: 590px) {
  .namenav {
    top: 30px;
    left: 30px;
  }
  .namenav a {
    font-size: 20px;
  }
  .proj-button {
    width: 95vw;
  }
  .proj-title {
    font-size: 60px;
    grid-column: 4 / 10;
    align-self: start;
    justify-self: end;
    margin-right: 10px;
  }
  .proj-subtitle {
    align-self: start;
  }
  .proj-index {
    grid-column: 6 / 9;
    margin-top: 0;
    margin-top: -5%;
    align-self: start;
  }
  .project .proj-img-sub {
    display: none;
  }

  .page p {
    font-size: 11pt;
  }
  .page-img {
    right: 0;
  }
  .page-title-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 4fr 1fr 4fr;
    overflow: visible;
  }
  .page-title {
    font-size: 50px;
  }
  .page-subtitle {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
  .page-desc {
    grid-row: 3 / 4;
  }
  .page-desc-link,
  .page-desc-password {
    grid-row: 4 / 5;
  }
  .page-validate {
    grid-row: 5 / 6;
  }
  .page-desc-password button {
    -webkit-appearance: none;
    color: #000;
    font-family: proxima-nova, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  }
  .page-category {
    grid-column: 1 / 2;
    grid-row: 5 / 6;
    align-self: start;
    justify-self: start;
    text-align: left;
  }

  .content-container {
    grid-template-columns: 1fr;
    row-gap: 1em;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 90vw;
  }
  .content-body {
    grid-column: 1 / 2;
  }
  .content-whole img {
    width: calc(100% - 20px);
    margin: 0 10px;
  }

  .about .page-title {
    font-size: 50px;
    line-height: 60px;
  }
}
